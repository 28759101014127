import React from "react"
import Layout from "../../components/Layout"
import AppScreenshot from "../../images/screenshots/Therapeut_innen_Aufgaben.png"
import MobilePresentationImage from "../../components/MobilePresentationImage"
import SplitView from "../../components/SplitView"
import { Button } from "../../components/text/Button"
import { H1 } from "../../components/text/H1"
import { Subheadline } from "../../components/text/Subheadline"
import Section from "../../components/Section"
import CTAElement from "../../components/CTAElement"
import HausaufgabenScreenshot from "../../images/screenshots/screenshots_psychotherapeut_innen_einzelneaufgabe.png"
import HausaufgabenOverviewScreenshot from "../../images/screenshots/screenshots_psychotherapeut_innen_aufgaben_uebsicht.png"
import AufgabenZuteilenScreenshot from "../../images/screenshots/Aufgaben_zuteilen_Psychotherapeut_in.png"

export interface FunktionenTherapeutInnenProps {}

const FunktionenTherapeutInnen: React.SFC<FunktionenTherapeutInnenProps> = () => {
  return (
    <Layout>
      <SplitView
        elementLeft={
          <>
            <H1 text="Unterstützung bei Ihrer Arbeit als Psychotherapeut*in" />
            <Subheadline text="Die Psychotherapie App unterstützt Sie dabei, Ihren Aufgabenkatalog digital bereitzustellen und Ihren Patient*innen zuzuweisen. So können Sie therapeutische Hausaufgaben einmalig erstellen und beliebig oft zuweisen. Dadurch sparen Sie Zeit und haben Ihre Aufgaben auf einem Blick. Zudem erleichtern Sie es Ihren Patent*innen, ihre therapeutischen Hausaufgaben zu erledigen, da sie mehr Informationen zur Verfügung haben und diese von überall zugänglich sind." />
            <div>
              <Button
                text="Mehr erfahren"
                to="/funktionen/psychotherapeut_innen/#material"
                className="text-center"
              />
            </div>
          </>
        }
        elementRight={
          <MobilePresentationImage src={AppScreenshot} alt="Screenshot App" />
        }
        scrollId="material"
      />

      <Section background id="material">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center -mx-4">
            <div className="flex flex-wrap items-center w-full lg:w-1/2 px-4">
              <div className="mb-6 w-full px-3">
                <img
                  className="mb-6 w-full rounded-lg"
                  src={HausaufgabenScreenshot}
                  alt=""
                />
              </div>
            </div>
            <div className="mb-12 lg:mb-0 w-full lg:w-1/2 px-4">
              <div className="max-w-md ml-auto">
                {/* <span className="text-green-600 font-bold">
                  Dolor sit amet consectutar
                </span> */}
                <h2 className="mb-6 text-4xl lg:text-5xl font-bold font-heading">
                  Text, Bild-, Audio- und Videomaterial einbetten
                </h2>
                <ul>
                  <li className="flex mb-4">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="mr-3 w-8 h-8 text-green-600"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                        />
                      </svg>
                    </div>
                    <div className="max-w-xs">
                      <h3 className="font-bold font-heading">
                        Hausaufgaben zugänglicher machen
                      </h3>
                      <p className="text-gray-500 leading-loose">
                        Erweitern Sie Ihre therapeutischen Hausaufgaben mit
                        passenden digitalen Materialien, um das Verständnis bei
                        Ihren Patient*innen zu erhöhen.
                      </p>
                    </div>
                  </li>
                  <li className="flex mb-4">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="mr-3 w-8 h-8 text-green-600"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
                        />
                      </svg>
                    </div>
                    <div className="max-w-xs">
                      <h3 className="font-bold font-heading">
                        Achtsamkeitsmeditationen
                      </h3>
                      <p className="text-gray-500 leading-loose">
                        Achtsamkeitsmeditationen einfach in eine Aufgabe
                        integrieren - kein umständliches Weiterleiten Ihrer
                        Dateien mehr notwendig.
                      </p>
                    </div>
                  </li>
                  <li className="flex">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="mr-3 w-8 h-8 text-green-600"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                    <div className="max-w-xs">
                      <h3 className="font-bold font-heading">
                        Psychoedukative Inhalte darstellen
                      </h3>
                      <p className="text-gray-500 leading-loose">
                        Helfen Sie Ihren Patient*innen, die Wirksamkeit Ihrer
                        Übungen zu verstehen und erhöhen Sie damit Ihren
                        Therapieerfolg.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center -mx-4">
            <div className="mb-12 lg:mb-0 w-full lg:w-1/2 px-4">
              <div className="max-w-md mr-auto">
                {/* <span className="text-green-600 font-bold">
                  Dolor sit amet consectutar
                </span> */}
                <h2 className="mb-6 text-4xl lg:text-5xl font-bold font-heading">
                  Informationen von Patient*innen strukturiert abfragen
                </h2>
                <ul>
                  <li className="flex mb-4">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="mr-3 w-8 h-8 text-green-600"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                        />
                      </svg>
                    </div>
                    <div className="max-w-xs">
                      <h3 className="font-bold font-heading">
                        Dankbarkeitstagebücher
                      </h3>
                      <p className="text-gray-500 leading-loose">
                        Geben Sie Ihren Patient*innen die Möglichkeit, Texte mit
                        verschiedenen Formatierungen zu verfassen. Dabei können
                        Sie kürzere Texte oder ganze Tagebucheinträge abfragen.
                      </p>
                    </div>
                  </li>
                  <li className="flex mb-4">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="mr-3 w-8 h-8 text-green-600"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M13 5l7 7-7 7M5 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                    <div className="max-w-xs">
                      <h3 className="font-bold font-heading">
                        Einfache Übertragung von bisherigen therapeutischen
                        Hausaufgaben
                      </h3>
                      <p className="text-gray-500 leading-loose">
                        Übertragen Sie Ihre bisherigen therapeutischen
                        Hausaufgaben mit vorgefertigten Texten und
                        Eingabeelementen in die App.
                      </p>
                    </div>
                  </li>
                  <li className="flex">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="mr-3 w-8 h-8 text-green-600"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                        />
                      </svg>
                    </div>
                    <div className="max-w-xs">
                      <h3 className="font-bold font-heading">
                        Zielgerichtete Aufgabenstellungen
                      </h3>
                      <p className="text-gray-500 leading-loose">
                        Passen Sie die Aufgabenstellungen für alle Patient*innen
                        individualisiert an oder verwenden Sie Aufgaben
                        mehrfach. <br />
                        So sparen Sie Zeit, sobald Sie einen Hausaufgabenkatalog
                        aufgebaut haben. <br />
                        Sie können aber auch schnell eine Hausaufgabe für
                        einzelne Patient*innen individualisieren.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-wrap items-center w-full lg:w-1/2 px-4">
              <div className="mb-6 w-full px-3">
                <MobilePresentationImage
                  src={AufgabenZuteilenScreenshot}
                  alt="Screenshot App"
                />
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section background>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center -mx-4">
            <div className="flex flex-wrap items-center w-full lg:w-1/2 px-4">
              <div className="mb-6 w-full px-3">
                <img
                  className="mb-6 w-full rounded-lg"
                  src={HausaufgabenOverviewScreenshot}
                  alt=""
                />
              </div>
            </div>
            <div className="mb-12 lg:mb-0 w-full lg:w-1/2 px-4">
              <div className="max-w-md ml-auto">
                {/* <span className="text-green-600 font-bold">
                  Dolor sit amet consectutar
                </span> */}
                <h2 className="mb-6 text-4xl lg:text-5xl font-bold font-heading">
                  Therapeutischer Hausaufgabenkatalog
                </h2>
                <ul>
                  <li className="flex mb-4">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="mr-3 w-8 h-8 text-green-600"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
                        />
                      </svg>
                    </div>
                    <div className="max-w-xs">
                      <h3 className="font-bold font-heading">
                        Hausaufgaben einmalig erstellen - mehrfach zuweisen
                      </h3>
                      <p className="text-gray-500 leading-loose">
                        Erstellen Sie einmalig Hausaufgaben und weisen Sie diese
                        so vielen Patient*innen zu, wie Sie möchten.
                      </p>
                    </div>
                  </li>
                  <li className="flex mb-4">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="mr-3 w-8 h-8 text-green-600"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
                        />
                      </svg>
                    </div>
                    <div className="max-w-xs">
                      <h3 className="font-bold font-heading">
                        Teilen Sie Ihre Hausaufgaben mit Kolleg*innen
                      </h3>
                      <p className="text-gray-500 leading-loose">
                        Sie haben die Möglichkeit, alle oder einzelne
                        Hausaufgaben zu exportieren und Kolleg*innen zur
                        Verfügung zu stellen. Genauso können Sie Hausaufgaben
                        von Kolleg*innen importieren. So sparen Sie Zeit, indem
                        Sie nicht alle Hausaufgaben erstellen müssen.
                      </p>
                    </div>
                  </li>
                  <li className="flex">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="mr-3 w-8 h-8 text-green-600"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                        />
                      </svg>
                    </div>
                    <div className="max-w-xs">
                      <h3 className="font-bold font-heading">
                        Vorgefertigte Hausaufgabensammlung von renommierten
                        Verlagen (in Entwicklung)
                      </h3>
                      <p className="text-gray-500 leading-loose">
                        Damit Sie nicht alle Hausaufgaben selbst erstellen
                        müssen und immer die neusten wissenschaftlich getesteten
                        Hausaufgaben zur Verfügung haben. Möchten wir in Zukunft
                        mit Verlagen kooperieren, um Ihnen Hausaufgaben von
                        diesen bereitzustellen.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <CTAElement />
    </Layout>
  )
}

export default FunktionenTherapeutInnen
